var page_home_loaded = 1;
global.page_home_loaded = page_home_loaded;

global.jQuery = require('jquery');
var slickInit = require('slick-carousel');

page_home = {
	load: function(){
		this.homeVisual();
		this.homeAziendaVisual();
		this.settoriGal();
	},
	homeVisual: function(){	
		jQuery('.homeVisual__gallery').slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			fade: true,
			speed: 700,
			asNavFor: '.homeVisual__textGallery',
			arrows: false,
			dots: false
		});
		jQuery('.homeVisual__textGallery').slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			asNavFor: '.homeVisual__gallery',
			focusOnSelect: true,
			autoplay: true,
  			autoplaySpeed: 2400,
			speed: 800,
			arrows: true,
		  	dots: true,
		  	prevArrow: $('.slick-prev-custom'),
		  	nextArrow: $('.slick-next-custom'),
		});

		jQuery('.homeVisual__gallery, .homeVisual__textGallery').on('beforeChange', function () {
			siteGlobal.lazyLoad();
		});
	},
	homeAziendaVisual: function(){	
		jQuery('.homeAz__gall').slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			speed: 700,
			arrows: true,
			prevArrow: $('.az-prev-custom'),
		  	nextArrow: $('.az-next-custom'),
		});
	},
	settoriGal: function(){
		if (window.outerWidth < 768) {
			jQuery('.homeSett__blocks').slick({
				slidesToShow: 1,
				slidesToScroll: 1,
				speed: 700,
				arrows: true,
				prevArrow: $('.sett-prev-custom'),
			  	nextArrow: $('.sett-next-custom'),
			});
		}
	}
}

$(window).on('load', function(){
	page_home.load();
})

$(window).resize(function(e){
	if(window.innerWidth < 768) {
        if(!jQuery('.homeSett__blocks').hasClass('slick-initialized')){
            page_home.settoriGal();
        }
    } else {
        if(jQuery('.homeSett__blocks').hasClass('slick-initialized')){
            jQuery('.homeSett__blocks').slick('unslick');
        }
    }
});